import * as React from 'react'
import Layout from '../templates/layout'
import { Link } from 'gatsby'

import '../scss/sitemap.scss'
import ExternalLink from '../components/ExternalLink'

const SiteMap = () => {
	return (
		<Layout>
			<div className='grid grid-cols-12 py-10'>
				<div className='md:col-start-3 col-start-2 md:col-span-8 col-span-10'>
					<ul id='sitemap-list'>
						<li>
							<Link to='/'>Home</Link>
						</li>
						<li>
							<Link to='/egfr-exon20'>EGFR Exon20</Link>
						</li>
						<li>
							<Link to='/about-exkivity'>About EXKIVITY</Link>
						</li>
						<li>
							<Link to='/about-exkivity'>Study Results</Link>
						</li>
						<li>
							<Link to='/taking-exkivity'>Taking EXKIVITY</Link>
						</li>
						<li>
							<Link to='/possible-side-effects'>Possible Side Effects</Link>
						</li>
						<li>
							<Link to='/resources'>Resources</Link>
						</li>
						<li className='ml-4'>
							<Link to='/resources'>Useful Resources</Link>
						</li>
						<li className='ml-4'>
							<Link to='/takeda-oncology-here2assist'>
								Takeda Oncology Here2Assist
							</Link>
						</li>
						<li>
							<Link to='/register'>Register for Updates</Link>
						</li>
						<li>
							<a
								href='https://www.takeda.com/en-us/terms-of-use'
								target='_blank'
								rel='noreferrer'
							>
								Terms of Use
							</a>
						</li>
						<li>
							<a
								href='https://www.takeda.com/privacy-notice'
								target='_blank'
								rel='noreferrer'
							>
								Privacy Policy
							</a>
						</li>
						<li>
							<a
								href='https://www.takeda.com/en-us/who-we-are/contact-us'
								target='_blank'
								rel='noreferrer'
							>
								Contact Us
							</a>
						</li>
					</ul>
				</div>
			</div>
		</Layout>
	)
}
export default SiteMap
